<template>
    <!-- compose email -->
    <div class="modal modal-sticky" id="compose-mail" data-bs-keyboard="false">
        <div class="modal-dialog modal-lg modal-dialog-scrollable">
            <div class="modal-content p-0">
                <div class="modal-header">
                    <h5 class="modal-title">Compose Mail</h5>
                    <div class="modal-actions">
                        <a href="#" class="text-body me-75"><i data-feather="minus"></i></a>
                        <a href="#" class="text-body me-75 compose-maximize"><i data-feather="maximize-2"></i></a>
                        <a class="text-body" href="#" data-bs-dismiss="modal" aria-label="Close">
                            <i class="bi bi-x"></i>
                        </a>
                    </div>
                </div>
                <div class="modal-body flex-grow-1 p-0">
                    <form class="compose-form">
                        <div class="my-1 mx-1">
                            <base-badge  badgeColor="primary" class="mb-1" >John x</base-badge>
                            <base-badge  badgeColor="primary" class="mb-1" >Emeka x</base-badge>
                            <base-badge  badgeColor="primary" class="mb-1" >Emeka x</base-badge>
                            <base-badge  badgeColor="primary" class="mb-1" >Emeka x</base-badge>
                            <base-badge  badgeColor="primary" class="mb-1" >Emeka x</base-badge>
                            <base-badge  badgeColor="primary" class="mb-1" >Emeka x</base-badge>
                            <base-badge  badgeColor="primary" class="mb-1" >Emeka x</base-badge>
                            <base-badge  badgeColor="secondary"   contenteditable="true">
                                Add new recipient <i class="bi bi-plus-circle-fill ps-2"></i>
                            </base-badge>




                        </div>
                        <div class="mb-1">
                            <input type="text" class="form-control remove-edges" id="title" name="title" placeholder="Subject" required />
                        </div>
                        <div class="mb-1">
                            <div contenteditable="true" class="p-1 big-content-editable">Message</div>
                        </div>
                    </form>
                </div>
                <div class="p-1 bg-light">
                    <span class="me-1 mb-1"><i class="bi bi-file-pdf "></i> Attached file</span>
                </div>
                <div class="modal-footer p-1" style="justify-content: space-between">
                    <div>
                        <span class="ms-1"><i class="bi bi-toggle-on bs-icon-small text-primary"></i></span>
                        <span class="ms-1"><i class="bi bi-upload bs-icon-small"></i></span>
                        <span class="ms-1"><i class="bi bi-trash-fill bs-icon-small"></i></span>
                    </div>
                    <button type="button" class="btn btn-primary "  data-bs-dismiss="modal">Send message</button>
                </div>
            </div>
        </div>
    </div>
    <!--/ compose email -->
</template>

<style scoped>
[contenteditable] {
  outline: 0px solid transparent;
}

.big-content-editable {
  height:20rem;
}
</style>
