<!-- Timeline Starts -->
<template>
    <section class="basic-timeline">
        <div class="row">
            <div class="col-lg-12">
                <base-card-plain :showHeader="false" addBodyPadding='p-0'>
                    <template #default>
                        <div class="input-group  p-0">
                            <input type="text" class="form-control  search-bar-border p-1"
                             placeholder="Search task"  />
                            <button class="btn btn-flat-secondary search-bar-border"  id="searchBtn" type="button">
                                <i class="bi bi-search bs-icon-btn"  ></i>
                            </button>
                        </div>
                        <ul class="timeline m-0">
                            <li class="timeline-item message-border my-2">
                                <span class="timeline-point timeline-point-secondary" style="left:1rem;">
                                    <div class="avatar">
                                        <base-image
                                            imgName="12-small.png"
                                            imgGeo="avatars"
                                            imgAlt="avatar"
                                            :imgDim="[32,32]"
                                            >
                                        </base-image>
                                    </div>
                                </span>
                                <div class="timeline-event ps-2">
                                    <div class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1">
                                        <h6>Client Meeting</h6>
                                        <span class="timeline-event-time pe-1">45 min ago</span>
                                    </div>
                                    <p>Project meeting with john @10:15am.</p>
                                    <div class="d-flex flex-row align-items-center">
                                        <div class="avatar">
                                            <base-image
                                                imgName="12-small.png"
                                                imgGeo="avatars"
                                                imgAlt="avatar"
                                                :imgDim="[38,38]"
                                                >
                                            </base-image>
                                        </div>
                                        <div class="ms-50">
                                            <h6 class="mb-0">John Doe (Client)</h6>
                                            <span>CEO of Infibeam</span>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li class="timeline-item message-border my-2">
                                <span class="timeline-point timeline-point-secondary" style="left:1rem;">
                                    <div class="avatar">
                                        <base-image
                                            imgName="12-small.png"
                                            imgGeo="avatars"
                                            imgAlt="avatar"
                                            :imgDim="[32,32]"
                                            >
                                        </base-image>
                                    </div>
                                </span>
                                <div class="timeline-event ps-2">
                                    <div class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1">
                                        <h6>Client Meeting</h6>
                                        <span class="timeline-event-time pe-1">45 min ago</span>
                                    </div>
                                    <p>Project meeting with john @10:15am.</p>
                                    <div class="d-flex flex-row align-items-center">
                                        <div class="avatar">
                                            <base-image
                                                imgName="12-small.png"
                                                imgGeo="avatars"
                                                imgAlt="avatar"
                                                :imgDim="[38,38]"
                                                >
                                            </base-image>
                                        </div>
                                        <div class="ms-50">
                                            <h6 class="mb-0">John Doe (Client)</h6>
                                            <span>CEO of Infibeam</span>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li class="timeline-item message-border my-2">
                                <span class="timeline-point timeline-point-secondary" style="left:1rem;">
                                    <div class="avatar">
                                        <base-image
                                            imgName="12-small.png"
                                            imgGeo="avatars"
                                            imgAlt="avatar"
                                            :imgDim="[32,32]"
                                            >
                                        </base-image>
                                    </div>
                                </span>
                                <div class="timeline-event ps-2">
                                    <div class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1">
                                        <h6>Client Meeting</h6>
                                        <span class="timeline-event-time pe-1">45 min ago</span>
                                    </div>
                                    <p>Project meeting with john @10:15am.</p>
                                    <div class="d-flex flex-row align-items-center">
                                        <div class="avatar">
                                            <base-image
                                                imgName="12-small.png"
                                                imgGeo="avatars"
                                                imgAlt="avatar"
                                                :imgDim="[38,38]"
                                                >
                                            </base-image>
                                        </div>
                                        <div class="ms-50">
                                            <h6 class="mb-0">John Doe (Client)</h6>
                                            <span>CEO of Infibeam</span>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li class="timeline-item message-border my-2">
                                <span class="timeline-point timeline-point-secondary" style="left:1rem;">
                                    <div class="avatar">
                                        <base-image
                                            imgName="12-small.png"
                                            imgGeo="avatars"
                                            imgAlt="avatar"
                                            :imgDim="[32,32]"
                                            >
                                        </base-image>
                                    </div>
                                </span>
                                <div class="timeline-event ps-2">
                                    <div class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1">
                                        <h6>Client Meeting</h6>
                                        <span class="timeline-event-time pe-1">45 min ago</span>
                                    </div>
                                    <p>Project meeting with john @10:15am.</p>
                                    <div class="d-flex flex-row align-items-center">
                                        <div class="avatar">
                                            <base-image
                                                imgName="12-small.png"
                                                imgGeo="avatars"
                                                imgAlt="avatar"
                                                :imgDim="[38,38]"
                                                >
                                            </base-image>
                                        </div>
                                        <div class="ms-50">
                                            <h6 class="mb-0">John Doe (Client)</h6>
                                            <span>CEO of Infibeam</span>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li class="timeline-item message-border my-2">
                                <span class="timeline-point timeline-point-secondary" style="left:1rem;">
                                    <div class="avatar">
                                        <base-image
                                            imgName="12-small.png"
                                            imgGeo="avatars"
                                            imgAlt="avatar"
                                            :imgDim="[32,32]"
                                            >
                                        </base-image>
                                    </div>
                                </span>
                                <div class="timeline-event ps-2">
                                    <div class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1">
                                        <h6>Client Meeting</h6>
                                        <span class="timeline-event-time pe-1">45 min ago</span>
                                    </div>
                                    <p>Project meeting with john @10:15am.</p>
                                    <div class="d-flex flex-row align-items-center">
                                        <div class="avatar">
                                            <base-image
                                                imgName="12-small.png"
                                                imgGeo="avatars"
                                                imgAlt="avatar"
                                                :imgDim="[38,38]"
                                                >
                                            </base-image>
                                        </div>
                                        <div class="ms-50">
                                            <h6 class="mb-0">John Doe (Client)</h6>
                                            <span>CEO of Infibeam</span>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li class="timeline-item message-border my-2">
                                <span class="timeline-point timeline-point-secondary" style="left:1rem;">
                                    <div class="avatar">
                                        <base-image
                                            imgName="12-small.png"
                                            imgGeo="avatars"
                                            imgAlt="avatar"
                                            :imgDim="[32,32]"
                                            >
                                        </base-image>
                                    </div>
                                </span>
                                <div class="timeline-event ps-2">
                                    <div class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1">
                                        <h6>Client Meeting</h6>
                                        <span class="timeline-event-time pe-1">45 min ago</span>
                                    </div>
                                    <p>Project meeting with john @10:15am.</p>
                                    <div class="d-flex flex-row align-items-center">
                                        <div class="avatar">
                                            <base-image
                                                imgName="12-small.png"
                                                imgGeo="avatars"
                                                imgAlt="avatar"
                                                :imgDim="[38,38]"
                                                >
                                            </base-image>
                                        </div>
                                        <div class="ms-50">
                                            <h6 class="mb-0">John Doe (Client)</h6>
                                            <span>CEO of Infibeam</span>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li class="pb-3 ps-1">
                                <base-pagination>
                                </base-pagination>
                            </li>
                        </ul>
                    </template>
                </base-card-plain>
            </div>
        </div>
    </section>
</template>
<!-- Timeline Ends -->

<style scoped>
.message-border{
    border-left: 0px;
    border-bottom: 1px solid #ebe9f1;
}

.remove-edges{
    border-radius:0px;
}


.search-bar-border{
  border-style:solid;
  border-color:#ebe9f1;
  border-width:0px 0px 1px 0px;
}

.search-bar-active{
    border-color:#7367f0;
}
</style>
